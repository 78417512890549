footer ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-right: 15px;
  }

  span {
    margin-right: 15px;
  }
}

footer {
  .first-row {
    padding: 60px 20px;
    background-color: #dfdfdf;
    color: #000;

    ul {
      flex-wrap: wrap;

      @media screen and (max-width:767px) {
        flex-direction: column;
      }

      li {
        @media screen and (max-width:767px) {
          margin-bottom: 10px;
        }
      }
    }

    a.logo-wrapper {
      padding-bottom: 20px;
      display: block;

      img {
        width: 220px;
      }
    }

    li a {
      color: #000;
      text-decoration: none;
      font-size: 1rem;
    }
  }

  .second-row {
    padding: 40px;
    background-color: #000;
    color: #fff;

    @media screen and (max-width:767px) {
      justify-content: flex-start;
      padding: 40px 20px;
    }

    >div {
      @media screen and (max-width:767px) {
        flex-direction: column;
      }
    }

    div.lower-footer {
      display: flex;
      @media screen and (max-width:767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    ul {
      flex-wrap: wrap;
      align-items: center;

      @media screen and (max-width:767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      li {
        @media screen and (max-width:767px) {
          margin-bottom: 10px;
        }
      }
    }

    li a {
      color: #fff;
      text-decoration: none;
      font-size: 1rem;
    }

    span a {
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      margin-right: 15px;
    }
  }

  .social-media-icons {
    i {
      font-size: 1.5rem;
    }
  }
}
@import "./fonts";


html,
body {
	font-family: "OpenSansRegular", sans-serif;
	font-size: 1rem;
	font-weight: normal;

	p,
	button,
	span:not(.glyphicon),
	div,
	label,
	input,
	form {
		font-family: "OpenSansRegular", sans-serif;
		font-size: 1rem;

		@media screen and (max-width: 500px) {
			font-size: 1rem !important;
		}
	}

}

body.portaltype-eteaching-policy-frontpage #main-container,
body.portaltype-smd-policy-frontpage #main-container,
body.template-overview_smd_information_portal #main-container,
body.portaltype-smd-policy-focustopic #main-container,
body.portaltype-eteaching-policy-special #main-container {
	h1 {
		font-family: "OpenSansBold", sans-serif;
		font-size: 2.25rem;

		@media screen and (max-width:991px) {
			font-size: 1.75rem;
		}
	}

	h1.sectionTitle {
		font-size: 1.625rem;
		font-family: "OpenSansLight", sans-serif;
		margin-bottom: 2rem;
	}

	h1.sectionTitleSmd {
		font-size: 1.75rem;
		font-family: "OpenSansBold", sans-serif;
	}

	h2 {
		font-family: "OpenSansSemiBold", sans-serif;
		font-size: 1.375rem;
		border-bottom: none;

		a {
			border-bottom: none !important;

			&:hover {
				color: $iwm-darkgray !important;
			}
		}

		@media screen and (max-width:991px) {
			font-size: 1.5rem;
		}

	}

	h3 {
		font-size: 1.625rem;
		font-family: "OpenSansLight", sans-serif;

		@media screen and (max-width:991px) {
			font-size: 1.375rem;
		}
	}

	h4 {
		font-family: "OpenSansSemiBold", sans-serif;
		font-size: 1.625rem;
		color: black;

		a {
			border-bottom: none !important;

			&:hover {
				color: $iwm-darkgray !important;
			}
		}
	}
}


#main-container h1.documentFirstHeading {
	font-size: 2.25rem;
}

#main-container h1.sectionTitle {
	font-size: 1.625rem;
	font-family: "OpenSansLight", sans-serif;
	margin-bottom: 2rem;
}

.content h1 {
	font-family: "OpenSansBold", sans-serif;
	font-size: 28px;

	@media screen and (max-width:991px) {
		font-size: 1.75rem !important;
	}

	a {
		color: $primary;
		border-bottom: none !important;
		text-decoration: none !important;

		&:hover {
			color: $iwm-darkgray !important;
		}
	}

}

.content h2 {
	font-family: "OpenSansSemiBold", sans-serif;
	font-size: 26px;

	@media screen and (max-width:991px) {
		font-size: 1.2rem !important;
	}

	a {
		border-bottom: none !important;

		&:hover {
			color: $iwm-darkgray !important;
		}
	}

}


.content h3 {
	font-size: 24px;
	font-family: "OpenSansSemiBold", sans-serif;

	@media screen and (max-width:991px) {
		font-size: 1.375rem !important;
	}

	a {
		color: $primary;
		border-bottom: none !important;
		text-decoration: none !important;

		&:hover {
			color: $iwm-darkgray !important;
		}
	}

}

.content h4 {
	font-size: 20px;
	font-family: "OpenSansSemiBold", sans-serif;
	color: #212529;

	@media screen and (max-width:991px) {
		font-size: 1.1rem !important;
	}

	a {
		border-bottom: none !important;
		text-decoration: none;
		color: #212529;

		&:hover {
			color: $iwm-darkgray !important;
		}
	}

}

.content h5 {
	font-size: 18px;
	font-family: "OpenSansBold", sans-serif;
	color: #212529;
	;

	@media screen and (max-width:991px) {
		font-size: 1.375rem !important;
	}
}

.content h6 {
	font-size: 16px;
	font-family: "OpenSansBold", sans-serif;
	color: #212529;

	@media screen and (max-width:991px) {
		font-size: 1.375rem !important;
	}
}



#main-container {

	span:not(.glyphicon),
	p,
	div {
		font-size: 1rem;
		font-family: "OpenSansRegular", sans-serif;

	}

	p.lead {
		font-family: "OpenSansRegular", sans-serif;
		font-size: 1.25rem;
	}
}

.portlet-title {
	font-size: 1.625rem;
	font-family: "OpenSansLight", sans-serif;
	color: $primary !important;
	text-decoration: none !important;
}

.portlet-element {
	font-family: "OpenSansSemiBold", sans-serif;
	font-size: 1.625rem;
	color: $primary !important;
	text-decoration: none !important;
}


#main-container #content #content-core a:not(.btn):not(.page-link):not(.badge):not(.nav-link):not(.primary-button):not(.smd-primary-button) {
	color: $primary;
	text-decoration: none;
	border-bottom: 1px solid;

	&:hover {
		color: $orange;
	}
}

#main-container #content table a:not(.btn):not(.page-link):not(.badge) {
	font-family: "OpenSansSemiBold", sans-serif;
	color: $primary;
	border-bottom: none;
}

body.portaltype-badgeassertion .bg-secondary,
body.portaltype-openbadge .bg-secondary {
	a {
		font-family: "OpenSans", sans-serif;
		color: $gray !important;
		text-decoration: none;
		border-bottom: 1px solid;

		&:hover {
			color: $white !important;
		}
	}
}

#parent-fieldname-text *+h1 {
	margin-top: 3rem;
}
main#main-container a.portels-button {
    color: $white !important;
    background-color: $primary;
    border-radius: 40px;
    text-decoration: none;
    padding: 8px 20px;
    border: none;
    border-bottom: none !important;
    position: relative;
     font-family: "OpenSansSemiBold", sans-serif;
    display: block;
    width: 300px;
    margin: 20px 0;


    i {
        position: absolute;
        right: 8px;
        top: 4px;
        background-color: $iwm-darkgray;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 20px;

        &::before {
            position: relative;
            top: 5px;
            left: 10px;
        }
    }

    &:hover {
        background-color: $iwm-darkgray;
        border-color: $iwm-darkgray;

        i {

            background-color: black;
            border-color: $iwm-darkgray;

        }
    }

}

main#main-container a.primary-button {
    color: $white;
    background-color: $darkOrange;
    border-radius: 40px;
    text-decoration: none;
    padding: 8px 20px;
    position: relative;
    display: block;
     font-family: "OpenSansSemiBold", sans-serif;
    width: 300px;

    i {
        position: absolute;
        right: 8px;
        top: 4px;
        background-color: $iwm-darkgray;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 20px;

        &::before {
            position: relative;
            top: -2px;
            left: 2px;
        }
    }

    &:hover {
        background-color: $iwm-darkgray;
        border-color: $iwm-darkgray;

        i {

            background-color: $darkOrange;
            border-color: $iwm-darkgray;

        }
    }


}

main#main-container a.smd-primary-button {
    color: $white;
    background-color: $smdOrange;
    border-radius: 0;
    text-decoration: none;
    padding: 8px 20px;
    position: relative;
    display: table;
     font-family: "OpenSansSemiBold", sans-serif;
    width: auto;

    &:hover {
        background-color: $smdDarkOrange;

    }


}

body.portaltype-eteaching-policy-frontpage main#main-container .portlet-button,
body.portaltype-eteaching-policy-special main#main-container .portlet-button {
    color: $white;
    background-color: $primary;
    border-radius: 40px;
    text-decoration: none;
    padding: 8px 20px;
    position: relative;
     font-family: "OpenSansSemiBold", sans-serif;
    display: block;
    width: 300px;
    margin: 20px 0;

    i {
        position: absolute;
        right: 8px;
        top: 4px;
        background-color: $iwm-blue;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 20px;

        &::before {
            position: relative;
            top: 5px;
            left: 10px;
        }
    }

    &:hover {
        background-color: $iwm-blue;

        i {

            background-color: $primary;

        }
    }

}

.primary-button {
    color: $white;
    background-color: $orange;
    border-radius: 40px;
    text-decoration: none;
    border: 1px solid $orange;
    padding: 8px 20px;
    min-width: 300px;
    text-align: left;
    position: relative;
    display: block;
     font-family: "OpenSansSemiBold", sans-serif;

    i {
        position: absolute;
        right: 5px;
        top: 5px;
        background-color: $braun;
        border-radius: 50%;
        padding: 8px;
    }

    &:hover {
        background-color: $braun;

        i {

            background-color: $orange;

        }
    }
}

.button-spacing-top{
    margin-top: 40px
}
div#searchbox input.ais-SearchBox-input.form-control.form-control-sm.border.border-light.text-dark {
    border: 1px solid #888;
}

body.site-eteaching.template-eteaching-search {

    article#content {
        >.container {
            padding: 1rem 0;
        }

        div.refinement-label {
            margin-bottom: 15px;
        }

        a.hit-link {
            font-family: "OpenSansSemiBold", sans-serif;
            padding-bottom: 5px;
            color: $primary;
            text-decoration: none;
            border-bottom: 3px solid $orange;
            display: block;
            margin-bottom: 10px;
        }

        input.ais-SearchBox-input.form-control.form-control-sm.border.border-light.text-dark.ais-SearchBox-input {
            border-radius: 0;
            border: 1px solid $primary;

            &::placeholder {
                color: $primary;
            }
        }

        div.hit-meta {
            font-family: "OpenSansSemiBold", sans-serif;
            color: $primary;
            margin-bottom: 10px;
            margin-top: 10px;

            span {
                font-family: "OpenSansSemiBold", sans-serif;
            }

            span.hit-modified {
                color: $orange;
            }

            div.hit-path {
                margin-top: 10px;
            }
        }
    }

    mark.ais-Highlight-highlighted,
    mark.ais-Snippet-highlighted {
        background-color: $orange;
        color: $white;
    }

    section#portal-column-content {
        width: 100%;
    }

    .ais-RefinementList-list {
        padding-left: 0;
    }

    .search-panel {
        @media screen and (max-width:500px) {
            flex-direction: column;
            gap: 15px;
        }

    }

    #search-control {
        @media screen and (max-width:500px) {
            flex-direction: column;
            gap: 15px;
        }
    }
}
.nav-tabs a.nav-link.active {
    color:$darkOrange;
}
a.active {
    color: $darkOrange;
    text-decoration: underline;
    text-decoration-color: $darkOrange;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
};
a.nav-link {
    color: $darkOrange;
}
a.nav-link:hover {
    color: $darkOrange;
}

a.nav-link:active {
    color: $darkOrange;
}

button.btn-primary {
    background-color: $darkOrange;
    border-color: $darkOrange;
}
button.btn-primary:hover {
    background-color: $orange;
    border-color: $darkOrange;
}
.formControls button.btn-primary:active {
    background-color: $orange;
    border-color: $darkOrange;
}
button.btn-primary.disabled {
    background-color: $orange;
    border-color: $darkOrange;
}
a.badge {
    text-decoration: underline;
    text-decoration-color: $darkOrange;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
};

a.badge:hover{
    color: $darkOrange;
}

td a{
    text-decoration: underline;
    text-decoration-color: $darkOrange;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
}

#main-container #content table a:not(.btn):not(.page-link):not(.badge):hover{
    color: $darkOrange;
}

body.template-personal-information section#portal-column-content{
    @media only screen and (min-width: 992px) {
        margin-left: 16.66666667%
      }
}

body.template-personal-preferences section#portal-column-content{
    @media only screen and (min-width: 992px) {
        margin-left: 16.66666667%
      }
}
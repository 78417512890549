nav#portal-breadcrumbs {
    background-color: transparent;
    a {
        color: $dark-gray;
        text-decoration: inherit;
      }
    
    .container{
        padding-left: 0px;
    }
    .breadcrumb{
        padding-left: 0px;
    }
}
body.col-content section#portal-column-content{
    width: 66%;

    @media screen and (max-width:991px) {
        width: 100%;
    }
}


body.site-eteaching.template-listing_blog_entry section#portal-column-content,
body.site-eteaching.section-news section#portal-column-content {
    width: 66%;

    @media screen and (max-width:991px) {
        width: 100%;
    }

    div.entries+nav {
        justify-content: flex-start;
    }
}

.listing-title {
    margin-bottom: 50px;

    @media screen and (max-width:500px) {
        margin-bottom: 25px;
    }

}

.listing-entries {

    @media screen and (max-width:500px) {
        margin-top: 10px;
    }

    article {
      

        .article-image a {
            justify-content: center;

            @media screen and (max-width:991px) {
                justify-content: flex-start;
            }
        }
    }
}



#main-container h2.card-heading {
    font-size: 1.625rem;

    >a {
        color: $primary;
        text-decoration: none;

        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }
}

#main-container h2.card-heading-mobile {
    font-size: 1.625rem;

    >a {
        color: $primary;
        text-decoration: none;

        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }
}



.card-description {
    font-size: 1rem;
}


#filter-btn {
    color: $orange;
    font-size: 1rem;
    cursor: pointer;
    font-family: "OpenSans",sans-serif;

    svg {
        margin-top: -3px;
    }
}

#site-actions {
    color: $orange;
    font-size: 1.5rem;
    cursor: pointer;
    font-family: "OpenSans",sans-serif;

    svg {
        margin-top: -3px;
    }
}

.filter-icon {
    margin-top: -5px;
}

.filter-title {
    height: 3em;
    font-weight: bold;
    cursor: pointer;

    @media screen and (max-width:900px) {
        height: 1.5em;
    }
}

.filter-close-btn {

    h6 {
        cursor: pointer;
    }

    svg {
        margin-top: 1px;
        margin-right: 5px;
        cursor: pointer;
    }
}

.filter-function {
    margin-top: 50px;
    bottom: 25%;
    right: 33%;

    @media screen and (max-width:500px) {
        position: static;
        margin-bottom: 50px;
        margin-top: 20px;
        width: 100%;
    }

    @media screen and (max-width:1200px) {
        margin-top: 30px;
    }

    button {
        background: $darkOrange 0% 0% no-repeat padding-box;
        border: 0px;
        opacity: 1;
        border-radius: 40px;
        padding: 8px 20px;
        color: $white;
        font-weight: 800;
        position: relative;
        width: 100%;
        margin: auto;


        @media screen and (max-width:500px) {
            width: 100%;
        }

        .search-btn-mobile {
            position: absolute;
            top: 5px;
            right: 2%;
            background-color: white;
            border-radius: 20px;

        }

        &:hover {
            background-color: $iwm-darkgray;
            border-color: $iwm-darkgray;

            i {

                background-color: $darkOrange;
                border-color: $iwm-darkgray;

            }
        }

    }
}

.filter-close-icon {
    margin-top: -9px;
    cursor: pointer;
}


.search-field {
    border-radius: 10px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    width: 100%;
    height: 2.5em;
    padding-left: 15px;
}

.search-field-mobile {
    border-radius: 10px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    width: 100%;
    height: 2.5em;
}

.search-container {
    position: relative;
    width: 100%;
}

.search-btn {
    position: absolute;
    top: 13%;
    right: 1%;

    @media screen and (max-width:770px) {
        right: 3%;
    }

    &-mobile {
        position: absolute;
        top: 13%;
        right: 2%;
    }
}

.filter-introduction {
    height: 2.4rem;
}

.filter-section {
    z-index: 10;
}

.filter-result {
    background-color: $gray;
    box-shadow: 0 0 0 100vmax $gray;
    clip-path: inset(0 -100vmax);
    padding: 25px 0;
    position: relative;

}

#accordion-button {
    background-color: $gray;
    font-weight: 800;
}

#accordion-button-border {
    border-bottom: 1px solid $primary;
    margin: 8px;
    opacity: 1;
}

.accordion-body {
    background-color: $gray;

}

#accordionExample {
    --bs-accordion-active-color: $primary;
    --bs-accordion-btn-focus-border-color: $gray;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0;
}

.accordion-title {
    font-weight: 700;
}

#accordion-item-no-border {
    border: none;
}

.filter-mobile-hide {
    display: none;
}

.filter-mobile-show {
    display: block;
}

.filter-desktop-hide {
    display: none;   
}

.Desktop-filter-container {
    margin: auto;

    @media screen and (max-width:500px) {

    width: 100%;
    }
}


.filter-mobile-line-split {
    margin: auto;
    border: 1px solid black;
    width: 97%;
    opacity: 1;
}


#listing-page-container {
    margin-left: -16px;

    @media screen and (max-width:768px) {

        margin-left: 0;
    }
}

.orange-checked {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    float: left;
    margin-left: -1.5em;
    border-radius: 0.25em;

    &:checked {
        background-color: $orange;
        border-color: $orange;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    }
}



.dynamic-button {
    background-color: $orange;
    color: #fff;
    border-radius: 25px;
    margin: 10px 6px;
    padding: 8px 36px;
    border: none;
    position: relative;

    i {
        border-radius: 50%;
        padding: 5px;
        background-color: $white;
        color: #707070;
        position: absolute;
        right: 5px;
        top: 6px;
    }

}

body.template-listing-page.site-eteaching section#portal-column-content {
    width: 66%;
    margin-left: 16.66666667%;
    margin-right: 16.66666667%;

    @media screen and (max-width:991px) {
        margin-left: 0%;
        margin-right: 0%;
    }

    div.mapListe {
        margin-bottom: 30px;
    }
}

.mobile-listing-image {
 height: 50%;
}



.list-group-item:hover {
  color: #3b5e81;
}

.listing-entries {

    h2 {
        font-family: "OpenSansSemiBold",sans-serif;
    }

    .supporting-information {
               font-family: "OpenSansSemiBold",sans-serif !important;

    }
}


.image-listing {
    height: 220px;

    @media screen and (min-width:770px) {
        height: 150px !important;
    }


    @media screen and (min-width:996px) {
        height: 200px !important;
    }
}

.journal-image {
    height: 190px;

    @media screen and (min-width:996px) {
        height: 170px !important;
    }
}

.listing-image {
    @media screen and (max-width:768px) {
        width: 200px;
        height: 200px !important;
        object-fit: contain;
    }
}
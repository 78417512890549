@font-face {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  src: local("OpenSansRegular"), url(/++theme++eteachingtheme2/fonts/OpenSans-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "OpenSansLight";
  font-style: normal;
  font-weight: 300;
  src: local("OpenSansLight"), url(/++theme++eteachingtheme2/fonts/OpenSans-Light.woff2) format("woff2");
}


@font-face {
  font-family: "OpenSansBold";
  font-style: normal;
  src: local("OpenSansBold"), url(/++theme++eteachingtheme2/fonts/OpenSans-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "OpenSansSemiBold";
  font-style: normal;
  src: local("OpenSansSemiBold"), url(/++theme++eteachingtheme2/fonts/OpenSans-Semibold.woff2) format("woff2");
}
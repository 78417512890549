body.site-eteaching:not(.portaltype-eteaching-policy-frontpage) aside#portal-column-two {
    width: 100%;
    padding: 0;
}

body.site-eteaching.portaltype-eteaching-policy-frontpage aside#portal-column-two {
    @media screen and (min-width:992px) {
        width: 33.3%;
        flex: 0 0 auto;
    }

}

body.site-eteaching:not(.portaltype-eteaching-policy-frontpage) aside#portal-column-two {


    div.portletWrapper {

        &:last-child>div.card.portlet {
            margin-bottom: 0;
        }

        &:nth-of-type(even) {
            background-color: #ddd;

            div.card.portlet {
                background-color: transparent;
            }
        }



        div.card.portlet {


            border: none;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 40px;
            padding: 40px 0;

            @media screen and (min-width:1400px) {
                max-width: 1320px;
            }

            @media screen and (max-width:1200px) {
                max-width: 1140px;
            }

            @media screen and (max-width:992px) {
                max-width: 960px;
            }

            @media screen and (max-width:768px) {
                max-width: 720px;
            }

            @media screen and (max-width:576px) {
                max-width: 540px;
            }

            div.card-header,
            div.card-footer {
                background-color: transparent;
            }

            div.card-header {

                a {
                    @extend .portlet-title;
                    text-transform: uppercase;
                }

                border: none !important;
            }

            div.card-body {
                border: none !important;
            }

            div.card-body ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }


            div.card-body ul li {
                width: 33.3%;
                border: none;

                @media screen and (max-width:991px) {
                    width: 50%;
                }

                @media screen and (max-width:600px) {
                    width: 100%;
                }

                &:hover {
                    background-color: transparent
                }
            }

            div.card-body ul li a {
                @extend .portlet-element;
                line-height: 1.1;
            }

            div.card-body ul li time {
                color: $orange;
                margin-top: 10px;
                display: block;
                font-family: "OpenSansSemiBold", sans-serif;
            }

            div.card-body ul li span.portletItemDetails {
                color: $orange;
                margin-top: 10px;
                display: block;
                font-family: "OpenSansSemiBold", sans-serif;
                text-transform: uppercase;

                span.timezone {
                    display: none;
                }

                span.location {
                    font-family: "OpenSansSemiBold", sans-serif;
                    color: $primary;
                }

            }



            div.card-body ul li:nth-child(n+4) {
                display: none;
            }

            &.portletEvents {
                a.previous-events {
                    display: none;
                }

            }

            div.card-footer {
                justify-content: center;
                display: flex;
                margin-top: 40px;
                border: none;

                a {
                    @extend .portlet-button;
                    color: #fff !important;

                }
            }

            p.portlet-description {
                color: $primary;
                margin-top: 25px;
                margin-bottom: 0;
                line-height: 1.4;
            }

        }
    }
}

section.media-section div.card-header {
    display: none;
}
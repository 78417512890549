input.allowMultiSubmit {
    background-color: $darkOrange;
    border-color: $darkOrange;
}
input.allowMultiSubmit:hover {
    background-color: $orange;
    border-color: $darkOrange;
}
input.allowMultiSubmit:active {
    background-color: $orange;
    border-color: $darkOrange
}
input.allowMultiSubmit.disabled {
    background-color: $darkOrange;
    border-color: $darkOrange;
}

div.alert-info{
    color: $iwm-orange;
    background-color:$iwm-sandy;
    border: 3px solid $iwm-orange !important;
}

span.bg-primary{
    background-color: $orange;
}

ul.pagination{
    margin-top: 25px;
}

a.dropdown-toggle{
    margin-right:15px;
}

input.form-check-input:checked{
 background-color: $darkOrange;
 border-color: $darkOrange;
}
body.template-login.site-eteaching {

    article > .login-wrapper {
        justify-content: left;
        width: 100%;
        display: block;

        div.card {
            max-width: none;

            .btn-primary{
                @extend .primary-button;
            }
        }
    }
}

body.template-register.site-eteaching {

    article > .login-wrapper {
        justify-content: left;
        width: 100%;
        display: block;

        div.card {
            max-width: none;

            .btn-primary{
                @extend .primary-button;
            }
        }
    }
}
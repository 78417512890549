:root {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
}

$primary: #111213;
$orange: #F05F00;
$darkOrange:#F05F00;
$braun:  #7F0248;
$blue: #3B5E81;
$white: #fff;
$darkBlue: #131F2B;
$gray: #E9E9E9;
$dark-gray: #787e7e;
$filter: #707070;
$smdOrange: #F05F00;
$smdDarkOrange: #D34B00;
$iwm-orange: #F05F00;
$iwm-darkgray: #434F4F;
$iwm-gray: #D7D9D9;
$iwm-sandy: #FFE5C3;
$iwm-red-purple: #7F0248;
$iwm-blue: #56b7e0;
$iwm-green: #9EC55F;


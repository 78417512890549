.page-btn {
    background-color: $orange;
    color: $white;
    border-radius: 0;
    font-family: "OpenSansSemiBold", sans-serif;

    &:hover {
        background-color: $primary;
    }
}

.black-btn {
    background-color: $primary;
    color: $white;
    border: none;
    font-family: "OpenSansSemiBold", sans-serif;
}

#main-container ul.pagination li.page-item {
    margin-right: 5px;

    >a {
        @extend .page-btn;

    }
    &.disabled{
        span{
            background-color: $orange;
            color: $white;
            padding: 6px 12px;
            display: block;
        }
    }

    &.previous,
    &.next {
        span.label {
            display: none;
        }
        span{
            font-family: "OpenSansSemiBold", sans-serif; 
        }
    }

    &.active {
        span.page-link {
            @extend .black-btn;
            border-radius: 0;
        }
    }

    &:first-child a.page-link,
    &:last-child a.page-link {
        border-radius: 0;
    }
}